import { Center, Spinner } from '@chakra-ui/react';
import React from 'react';

import { useColorScheme } from '../../theme/chakra/colors';

interface Props {
  show: boolean;
}
/**
 * A simple spinner used mainly for indicating page loading
 * Probably too large to be used for smaller sub-components
 */
const LoadingSpinner: React.FC<Props> = ({ show = false }) => {
  const spinnerColors = useColorScheme('makeSpinnerColors');

  return (
    <Center>
      {show ? <Spinner width="2rem" height="2rem" speed="1.0s" thickness="4px" {...spinnerColors} /> : null}
    </Center>
  );
};

export default LoadingSpinner;
