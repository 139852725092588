export enum PUBLIC_ROUTES {
  ONBOARDING = '/',
  AUTH = '/auth',
  NOT_FOUND = '/not-authorized',
  SIGN_OUT = '/signout',
  LANDING = '/v2/landing',
  EMAIL = '/v2/email',
  PROVIDER_SELECT = '/v2/provider-select',
  PROGRAM_INFO = '/v2/program-info',
}

export enum PRIVATE_ROUTES {
  ONBOARDING_ACCOUNT = '/onboarding-account',
  ONBOARDING_FINALIZE = '/onboarding-finalize',
  PAYMENT_SETUP_CONFIRMATION = '/payment-setup=confirmation',
  PAYMENT_SETUP = '/payment-setup',
  PRICING_TIER_SELECTION = '/pricing-tier-selection',
  SIGN_OUT = '/signout',
}
