'use client';

import { Buffer } from 'buffer';
import base64url from 'base64url';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const BRANDING_STATE_KEY = 'bnd'; // BraND state key
export const RETURN_URL_KEY = 'ret'; // RETurn
export const RETURN_JWT_KEY = 'jwt'; // JWT
export const RETURN_STATE_KEY = 'rts'; // ReTurn State

const RETURN_EXT_KEY = 'ext'; // EXTernal

interface AdditionalDataParams {
  [key: string]: boolean | number | string;
}

export type BrandingData = {
  networkAutoMigrateCcms: boolean;
  favicon?: {
    href: string;
    rel: string;
    sizes: string;
    type: string;
  };
  faviconSmall?: {
    href: string;
    rel: string;
    sizes: string;
    type: string;
  };
  logo?: string;
  logoSmall?: string;
};

export type ReturnStatePayload = {
  mode?: string;
  type?: string;
  networkId?: string;
  newSupply?: boolean;
  source?: string;
};
export type ParsedHash = {
  [BRANDING_STATE_KEY]?: BrandingData;
  [RETURN_URL_KEY]?: string;
  [RETURN_JWT_KEY]?: string;
  [RETURN_EXT_KEY]?: unknown;
  [RETURN_STATE_KEY]?: ReturnStatePayload;
};

interface QSType {
  hash: string | null;
  jwt: string;
  parsedHash?: ParsedHash;
  // this does not appear to be used anywhere and can likely be removed...
  getRedirectUrl: (parsedHash?: ParsedHash | null, additionalData?: AdditionalDataParams) => string;
}

export default function useQueryString(): QSType {
  const [hash, setHash] = useState<string | null>(null);

  useEffect(() => {
    if (window?.location?.hash && !hash) {
      setHash(window.location.hash);
      return;
    }
  }, [hash]);

  const parsedHash = useMemo(() => {
    if (!hash) return null;
    const payloadStr = hash?.split('#')?.[1];
    const rawDecodedJSON = base64url.decode(payloadStr);
    return JSON.parse(rawDecodedJSON);
  }, [hash]);

  const jwt = parsedHash?.[RETURN_JWT_KEY];

  const getRedirectUrl = useCallback((parsedHash?: ParsedHash | null, additionalData?: AdditionalDataParams) => {
    // all providers onboarded here should go to ccms...
    const base =
      process.env.NEXT_PUBLIC_WS_CCMS_APP_URL ??
      process.env.NEXT_PUBLIC_WS_AUTH_URLNEXT_PUBLIC_WS_AUTH_URL ??
      'https://www.wonderschool.com';

    if (!parsedHash) {
      return base;
    }

    const redirectHash = Buffer.from(
      JSON.stringify({
        [RETURN_JWT_KEY]: parsedHash?.[RETURN_JWT_KEY],
        [RETURN_EXT_KEY]: parsedHash?.[RETURN_EXT_KEY],
        [RETURN_STATE_KEY]: parsedHash?.[RETURN_STATE_KEY],
        ...additionalData,
      })
    ).toString('base64');

    return base + `/#` + redirectHash;
  }, []);

  return { jwt, hash, parsedHash, getRedirectUrl };
}
